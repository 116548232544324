import PropTypes from "prop-types";

import { Section, Container, getProperty, Title, Text, ArrowCurvedIcon } from "@gh/shared";

import * as styles from "./slogan.module.scss";

export const Slogan = ({ data }) => {
  const title = getProperty(data, "title");
  const banner = getProperty(data, "banner");

  return (
    <Section align="center">
      <Container>
        <Title size="s10" className={styles.title}>
          {title}
          <div className={styles.banner}>
            <ArrowCurvedIcon className={styles.icon} />
            <Text family="f3" size="s6" className={styles.text}>
              {banner}
            </Text>
          </div>
        </Title>
      </Container>
    </Section>
  );
};

Slogan.defaultProps = {
  data: {},
};

Slogan.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.string.isRequired,
  }).isRequired,
};
