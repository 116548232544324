import { Fragment } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, getProperty, Title, Text, Markdown } from "@gh/shared";

import { ImageCardLeft } from "../common/image-card";

export const Team = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");

  return (
    <Section>
      <ImageCardLeft
        fullScreen={true}
        content={
          <Fragment>
            <Title>{title}</Title>
            <Text>
              <Markdown content={content} />
            </Text>
          </Fragment>
        }
        image={<GatsbyImage image={image} alt="" />}
      />
    </Section>
  );
};

Team.defaultProps = {
  data: {},
};

Team.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }).isRequired,
};
