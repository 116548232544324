import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Pagination } from "swiper";

import { Container, getProperty } from "@gh/shared";

import * as styles from "./carousel-cards.module.scss";

export const CarouselCards = ({ images }) => (
  <div className={styles.wrapper}>
    <Container>
      <Swiper
        grabCursor={true}
        effect="cards"
        cardsEffect={{
          rotate: false,
          slideShadows: true,
        }}
        modules={[Pagination, EffectCards]}
        className={styles.slider}
        touchEventsTarget="container"
        pagination={{
          clickable: true,
          bulletClass: 'carousel-cards-bullet',
          horizontalClass: 'carousel-cards-bullets',
          bulletActiveClass: 'carousel-cards-bullet-active',
        }}
      >
        {images.map((item, index) => (
            <SwiperSlide key={index}>
              <GatsbyImage image={getProperty(item, "childImageSharp.gatsbyImageData")} alt="" />
            </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  </div>
);

CarouselCards.defaultProps = {
  images: [],
};

CarouselCards.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};
