import PropTypes from "prop-types";

import { CarouselCards } from "../common/carousel-cards";

import { Section, getProperty } from "@gh/shared";

export const Gallery = ({ data }) => {
  const images = getProperty(data, "images") || [];

  return (
    <Section align="center">
      <CarouselCards images={images} />
    </Section>
  );
};

Gallery.defaultProps = {
  data: {},
};

Gallery.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};
