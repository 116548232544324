import PropTypes from "prop-types";

import { Section, Container, getProperty, Title, Text } from "@gh/shared";

import * as styles from "./offers.module.scss";

export const Offers = ({ data }) => {
  const title = getProperty(data, "title");
  const items = getProperty(data, "items") || [];

  return (
    <Section align="center">
      <Container>
        <Title size="s4" family="f2" weight="w4" transform="uppercase">
          {title}
        </Title>
        <div className={styles.items}>
          {items.map((item, index) => {
            const label = getProperty(item, "label");
            const imgSrc = getProperty(item, "image.publicURL");

            return (
              <div key={index} className={styles.item}>
                <div className={styles.image}>
                  <img src={imgSrc} alt={label} />
                </div>
                <Text className={styles.label}>{label}</Text>
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  );
};

Offers.defaultProps = {
  data: {},
};

Offers.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};
