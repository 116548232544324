// extracted by mini-css-extract-plugin
export var banner = "slogan-module--banner--2xzW0";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var container = "slogan-module--container--pwJwM";
export var icon = "slogan-module--icon--Ed2hN";
export var text = "slogan-module--text--GcQWZ";
export var title = "slogan-module--title--9sdfD";