import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { Meta } from "./common/meta";
import { Intro } from "./hotel/intro";
import { About } from "./hotel/about";
import { Gallery } from "./hotel/gallery";
import { Slogan } from "./hotel/slogan";
import { Team } from "./hotel/team";
import { Kids } from "./hotel/kids";
import { Offers } from "./hotel/offers";

const Template = ({ data }) => {
  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const intro = getProperty(data, "markdownRemark.frontmatter.hotel.intro");
  const about = getProperty(data, "markdownRemark.frontmatter.hotel.about");
  const gallery = getProperty(data, "markdownRemark.frontmatter.hotel.gallery");
  const slogan = getProperty(data, "markdownRemark.frontmatter.hotel.slogan");
  const team = getProperty(data, "markdownRemark.frontmatter.hotel.team");
  const kids = getProperty(data, "markdownRemark.frontmatter.hotel.kids");
  const offers = getProperty(data, "markdownRemark.frontmatter.hotel.offers");

  return (
    <Fragment>
      <Meta data={meta} />
      <Intro data={intro} />
      <About data={about} />
      <Gallery data={gallery} />
      <Slogan data={slogan} />
      <Team data={team} />
      <Kids data={kids} />
      <Offers data={offers} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
        hotel {
          intro {
            title
            content
          }

          about {
            title
            content
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }

          gallery {
            images {
              childImageSharp {
                gatsbyImageData(width: 750, layout: CONSTRAINED)
              }
            }
          }

          slogan {
            title
            banner
          }

          team {
            title
            content
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }

          kids {
            title
            content
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }

          offers {
            title
            items {
              label
              image {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
